<!-- act图片小盒子 -->
<template>
  <div class="main-area auto" style="margin-top: 40px">
    <div class="text-center visi-hiden hide">
      <div class="main-tabs" style="margin-top: 40px">
        <div class="tab-item">活动预告</div>
        <div class="tab-item tab-active tab-item-border">正在进行中</div>
        <div class="tab-item">已结束</div>
      </div>
    </div>
    <div class="banner-item border-gray relative">
      <input type="hidden" class="start-value" value="1" />
      <input type="hidden" class="end-value" value="1" />
      <div class="img-box">
        <img
          class="banner-img-area pull-left"
          src="@/assets/Uploads/2022-05-05/627368825b627.png"
          alt="百度推广限时秒杀"
          title="百度推广限时秒杀"
        />
      </div>
      <div class="banner-desc-area relative">
        <h3 class="banner-title">百度推广限时秒杀</h3>
        <!--<p class="desc-text font-color-gray"></p>-->
        <p class="desc-text font-color-gray">
          开始时间：<span id="startTime">2022-05-04 00:00:00</span>
        </p>
        <p class="desc-text font-color-gray">
          结束时间：<span id="endTime">2022-05-25 00:00:00</span>
        </p>
        <div class="operate-area">
          <span class="join-btn ended">已过期</span>
        </div>
      </div>
      <span class="corner-mark absolute end"></span>
    </div>
    <div class="banner-item border-gray relative visi-hiden">
      <input type="hidden" class="start-value" value="1" />
      <input type="hidden" class="end-value" value="" />
      <div class="img-box">
        <a href="../cnreg/buy-index.html"
          ><img
            class="banner-img-area pull-left"
            src="@/assets/Uploads/2022-05-05/627368825b627.png"
            alt="电商服务全面升级"
            title="电商服务全面升级"
        /></a>
      </div>
      <div class="banner-desc-area relative">
        <a class="banner-title" href="../cnreg/buy-index.html">
          <h3 class="banner-title">电商服务全面升级</h3>
        </a>
        <!--<p class="desc-text font-color-gray"></p>-->
        <p class="desc-text font-color-gray">
          开始时间：<span id="startTime">2022-05-05 00:00:00</span>
        </p>
        <p class="desc-text font-color-gray">
          结束时间：<span id="endTime">2023-05-05 00:00:00</span>
        </p>
        <div class="operate-area">
          <input type="hidden" class="remin-time" value="18799269" />
          <!--<span class="remin-time">仅剩：-->
          <!--<span class="day"></span>天-->
          <!--<span class="hour"></span>小时-->
          <!--<span class="minute"></span>分钟-->
          <!--</span>-->
          <a href="../cnreg/buy-index.html" class="join-btn">立即参与</a>
        </div>
      </div>
      <span class="corner-mark absolute live"></span>
    </div>
    <div class="no-act-default visi-hiden hide">
      <span class="default-img-box"></span>
      <p class="tip-words">暂无更多活动</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped></style>
