<!-- 最新活动 -->
<template>
  <div>
    <Header />
    <Sidebar />
    <div class="about">
      <div class="act-banner">
        <div class="main-title">最新活动</div>
        <div class="main-title-desc">
          为您提供企业服务平台最新最热门活动信息
        </div>
      </div>
    </div>
    <Imgebox />
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import Imgebox from "../act/components/imgbox.vue";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
    Imgebox,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/module/act/index.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/statics/css/front/style.css";
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/common/common/common.css";
.nohide {
  display: block !important;
}
</style>
